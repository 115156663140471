<template>
    <div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url('../../auth-bg.jpg') no-repeat center center; background-color: #F0F5F9">
        <div class="auth-box">
            <div id="loginform">
                <div class="logo">
                    <span class="db"><img src="../../assets/img/logo-icon.png" class=" mb-3" alt="logo" style="width: 45px;"/></span>

                    <h5 class="font-medium ">{{ $t("apps.app_name") }}</h5>
                    <p>
                        Migrasi dari Whacenter v1
                    </p>
                </div>
                <!-- Form -->
                <div class="row">
                    <div class="col-12">
                        <form class="form-horizontal m-t-20" id="loginform" method="POST" v-on:submit.prevent="signIn">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                </div>
                                <input type="email" class="form-control form-control-lg" :placeholder="'email'" v-model="form.email" aria-label="email" aria-describedby="basic-addon1">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                </div>
                                <input class="form-control form-control-lg" :placeholder="$t('auth.password')" :type="passwordFieldType" v-model="form.password" required aria-label="Password" aria-describedby="basic-addon1">
                            </div>
                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <button class="btn btn-block btn-lg btn-info d-flex justify-content-center" style="gap: .5rem" :disabled="isLoading">
                                        <div v-if="isLoading" class="spinner-border spinner" role="status"></div>
                                        <div>Migrasi</div>
                                    </button>
                                </div>
                            </div>

                            <hr>
                            
                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <router-link to="/login" class="btn btn-block btn-lg btn-primary d-flex justify-content-center" style="gap: .5rem">
                                        <div>Sudah migrasi ? Masuk</div>
                                    </router-link>
                                </div>
                            </div>
                            
                            <div class="form-group m-b-0 m-t-10">
                                <div class="col-sm-12 text-center">
                                    <!-- {{ $t("auth.doesnot_have_account") }}
                                    <router-link to="/register" class="color-primary">
                                        {{ $t("auth.register_now") }}
                                    </router-link>
                                    <br />
                                    {{ $t("auth.need_verify_account") }}
                                    <router-link to="/verify" class="color-primary">
                                        {{ $t("auth.verify_now") }}
                                    </router-link> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                form: {
                    email: "",
                    password: "",
                },
                passwordFieldType: "password",
                isLoading: false,
            };
        },
        mounted() {},
        methods: {
            async signIn() {
                this.isLoading = true;

                try {
                    await this.$store.dispatch("migrate", this.form);
                    await this.$root.$emit("login", true);
                    this.$toast.success("Migrasi sedang dilakukan di backgorund, silahkan login dan tunggu data tersinkronisasi");
                    await this.$router.push("/login");
                    // window.location.reload()

                    // window.location.href = "/dashboard";
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
            togglePassword() {
                if (this.passwordFieldType == "password") {
                    this.passwordFieldType = "text"
                } else {
                    this.passwordFieldType = "password"
                }
            }
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn {
        line-height: 1rem !important;
    }
</style>